<script setup lang="ts">
import { useInfiniteQuery } from '@tanstack/vue-query'
import { BlogItems, Breadcrumb, SearchInput } from './components'
import InfiniteQuery from './components/InfiniteQuery.vue'

const router = useRouter()
const keyword = computed(() => String(router.currentRoute.value.query?.keyword ?? ''))
const newsQuerier = useInfiniteQuery({
  refetchOnWindowFocus: false,
  queryKey: [apisBlog.apiNewsList.id, keyword],
  queryFn: ({ pageParam }) => apisBlog.apiNewsList({ page_size: 15, keyword: keyword.value, ...pageParam }),
})

const breadcrumbs = computed(() => {
  return [
    { name: $t('1J6d94jQiyf2iQ5ynjsR'), path: localePath({ path: '/blog' }) },
    { name: $t('9u9BgajXz6LdDNnf6oWn') + (keyword.value ? `: ${keyword.value}` : ''), path: '' },
  ]
})
</script>

<template>
  <div class="page-content-width search-list-page">
    <div class="flex justify-between items-center mb-[40px] lt-tablet:block lt-tablet:mb-[20px]">
      <Breadcrumb :breadcrumbs="breadcrumbs" />
      <SearchInput class="w-[250px] h-[50px] lt-tablet:mt-[10px] lt-tablet:h-[40px] lt-tablet:w-full" :keyword="keyword" />
    </div>

    <InfiniteQuery v-slot="{ record }" class="new-list-wrap" :queryier="newsQuerier">
      <BlogItems.Style2
        :id="record.news_id"
        :key="record.news_id"
        :title="record.title"
        :alias="record.alias"
        :cover="record.covers"
        :summary="record.summary"
        :time="record.create_time"
        :category="record.category?.[0].alias ?? ''"
      />
    </InfiniteQuery>
  </div>
</template>

<style scoped lang="scss">
.search-list-page {
  margin: 15px auto 40px;
}

.new-list-wrap :deep(.list) {
  display: grid;
  gap: 40px;

  @media bp-lt-tablet {
    gap: 15px;
  }
}

.related-articles {
  margin-top: 100px;

  :deep(.list) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 30px;

    @media bp-lt-laptopl {
      gap: 15px;
    }
  }
}
</style>
